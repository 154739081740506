import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./style.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://village.pcube.in.net/api/auth/login",
        {
          username,
          password,
        }
      );

      console.log("Login Response:", response.data); // Check this in the browser console

      // Fetch the candidateId from the response if login is successful
      if (response.data.admin.candidateId) {
        localStorage.setItem("candidateId", response.data.admin.candidateId); // Store candidateId
      } else {
        console.error("candidateId not found in the response");
      }

      // Store token and adminId
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("adminId", response.data.admin.id);

      // Show success toast and navigate
      toast.success("Login successful!", {
        position: "top-right",
      });

      setTimeout(() => {
        navigate("/village-info");
      }, 2000);
    } catch (err) {
      toast.error("Check username and password", {
        position: "top-right",
      });
    }
  };
   useEffect(() => {
     // Get the full URL
     const url = window.location.href;

     // Extract the ID from the URL (assuming it's the last segment)
     const id = url.substring(url.lastIndexOf("/") + 1);

    //  setVillageId(id);
     console.log("Village ID:", id);
   }, []);
console.log();
  return (
    <div
      className="login-container"

    >
      <h2>Admin Login</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
      {/* <button
        onClick={() => navigate("/register")}
        style={{ marginTop: "20px" }}
      >
        Register
      </button> */}

      {/* Toastify container */}
      <ToastContainer />
    </div>
  );
};

export default Login;
