import React from "react";
import "./Sidebar.css";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import FileAddOutlined from "@ant-design/icons/FileAddOutlined"; // Import the add icon
import EditOutlined from "@ant-design/icons/EditOutlined";

function SideBar({
  isSideBar = false,
  villageNames,
  deleteVillage,
  isOpen,
  setOpen,
  editVillageData, // New function to handle adding images
  editVillageAllData,
}) {
  return (
    <div
      className="sideBarViewContainer"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className={`sidebarView ${isOpen ? "active" : "inactive"}`}>
        <div className="sidebarView__header">
          <div className="sidebarView__header__title">यादी (List)</div>
          <div
            className={`sidebarView__header__toggle `}
            onClick={() => setOpen(!isOpen)}
          >
            <CloseOutlined />
          </div>
        </div>
        <div className="sidebarView__list">
          {villageNames?.map((data, index) => (
            <div key={index} className="sidebarView__list__item">
              <div className="sidebarView__list__item__name">
                {data?.village_name}
              </div>
              <div className="sidebarView__list__item__actions">
                <EditOutlined
                  className="sidebarView__list__item__edit"
                  onClick={() => editVillageAllData(data)}
                />
                {/* Delete button */}
                <div
                  className="sidebarView__list__item__delete"
                  onClick={() => deleteVillage(data?.id)}
                >
                  <DeleteOutlined />
                </div>
                {/* Add Image button */}
                <div
                  className="sidebarView__list__item__add"
                  onClick={() => editVillageData(data)} // Add image function
                >
                  <FileAddOutlined />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SideBar;
