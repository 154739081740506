import React from "react";
import { Carousel } from "antd";

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const MyCarousel = ({ data, selectedVillage, selectedImageId }) => {
  if (!data || data.length <= 0 || selectedVillage === null) {
    return null; // Return null if there's no data or selected village
  }

  // Determine the index of the selected image
  const selectedIndex = data.findIndex((image) => image === selectedImageId);

  // Default to the first image if selectedImageId is not found
  const initialSlideIndex = selectedIndex !== -1 ? selectedIndex : 0;

  return (
    <Carousel arrows infinite={false} initialSlide={initialSlideIndex}>
      {data.map(
        (image, index) =>
          image && (
            <div className="carousel-img" key={index}>
              <img
                src={`https://village.pcube.in.net/uploads/${selectedVillage.candidate_id}/${image}`}
                alt={`${selectedVillage.village_name}`}
                style={{ maxWidth: "100%", marginBottom: "10px" }}
              />
            </div>
          )
      )}
    </Carousel>
  );
};

export default MyCarousel;
