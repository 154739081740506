import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import Header from "../headerfooter/Header";
import SideBar from "../headerfooter/SideBar";
import { Button, Col, Modal, Row } from "antd";
import VillageForm from "./VillageForm";

const VillageInfo = () => {
  const [villageName, setVillageName] = useState("");
  const [description, setDescription] = useState("");
  const [youtubeLink1, setYoutubeLink1] = useState("");
  const [youtubeLink2, setYoutubeLink2] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [imageWarning, setImageWarning] = useState("");
  const [villageNames, setVillageNames] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [datafield, setDataField] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [editData, setEditData] = useState({});
  const [isEdit, setIsEdit] = useState(true);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const LOCALSERVER_API = "https://village.pcube.in.net/api/village";

  // Modal logic
  const showModal = () => setIsModalOpen(true);

  const handleOk = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    const token = localStorage.getItem("token");
    try {
      await axios.post(`${LOCALSERVER_API}/edit/${modalData?.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          candidate_id: localStorage.getItem("candidateId"),
          village_name: modalData?.village_name,
        },
      });
      toast.success("Files uploaded successfully");
      formRef.current.reset();
    } catch (error) {
      console.error("Error while uploading files", error);
      toast.error("Error while uploading files");
    }
  };

  const handleCancel = () => setIsModalOpen(false);

  const handleImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const isValidSize = file.size >= 50 * 1024 && file.size <= 1024 * 1024;
      const isValidType = ["image/jpeg", "image/png"].includes(file.type);

      if (!isValidSize || !isValidType) {
        setImageWarning(
          "Image must be between 50 KB and 1024 KB and in JPG or PNG format."
        );
      } else {
        setImageWarning("");
        setImage(file);
      }
    } else {
      setImage(null);
      setImageWarning("");
    }
  };

    function editVillageAllData(data) {
      setIsEdit(false);
      setEditData(data);
    }


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
console.log(e.target)
console.log(e.target.village_name.value);
    // Create a new FormData object
    const formData = new FormData();
    if (!e.target.village_name.value){
      toast.error("Village Name is required");
      return;
    }
  // Append form inputs
  formData.append("village_name", e.target.village_name.value);
    formData.append("description", description);
    formData.append("youtube_link1", youtubeLink1);
    formData.append("youtube_link2", youtubeLink2);

    // Append image files if they exist
    if (image1) {
      formData.append("files", image1);
    }
    if (image2) {
      formData.append("files", image2);
    }

    // Log the form data for debugging
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    // Make the API call
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("You are not logged in. Please log in.");
        navigate("/login");
        return;
      }

      const response = await axios.post(`${LOCALSERVER_API}/add`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "candidate_id": localStorage.getItem("candidateId"),
        },
      });

      if (response.data.status_code === 401) {
        toast.error("Unauthorized: Please log in again.");
        navigate("/login");
      } else if (response.data.status_code === 200) {
        toast.success("Village added successfully");
        window.location.reload(); // Reload the page after success
      } else {
        toast.error("Error in adding village");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized: Please log in again.");
        navigate("/login");
      } else {
        toast.error("Error in adding village");
      }
    }
  };


  const fetchCandidateData = (candidateId) => {
    axios
      .get(`${LOCALSERVER_API}/all/${candidateId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => setVillageNames(response?.data?.data))
      .catch((error) => console.error("Error fetching candidate data:", error));
  };

  useEffect(() => {
    if (isOpen) fetchCandidateData(localStorage.getItem("candidateId"));
  }, [isOpen]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminId");
    localStorage.removeItem("candidateId");
    navigate("/login");
  };

  const editVillageData = (data) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const deleteVillage = (id) => {
    if (window.confirm("Are you sure you want to delete this?")) {
      axios
        .delete(`${LOCALSERVER_API}/delete/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => toast.success(response?.data?.message))
        .catch((error) => toast.error("Error deleting village"));
    }
  };

   const goTODashboard = () => {
     navigate("/dashboard");
   };

  return (
    <>
      <Header
        handleLogout={handleLogout}
        isSideBar={true}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      <SideBar
        isSideBar={true}
        villageNames={villageNames}
        deleteVillage={deleteVillage}
        editVillageData={editVillageData}
        isOpen={isOpen}
        setOpen={setOpen}
        editVillageAllData={editVillageAllData}
      />
      {isEdit ? (
        <div
          className="village-info-container"
          style={{ paddingRight: "40px" }}
        >
          <h2>विकास कामांची माहिती भरा.</h2>
          <button
            type="button"
            className="reouter-button"
            style={{ padding: "1px" }}
            onClick={goTODashboard}
          >
            विकास कामे बघा.
          </button>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <input type="text" name="village_name" placeholder="स्थळाचे नाव" />
            <textarea
              name="description"
              placeholder="माहिती"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <input
              type="text"
              name="youtube_link1"
              placeholder="यूट्यूब लिंक 1"
              value={youtubeLink1}
              onChange={(e) => setYoutubeLink1(e.target.value)}
            />
            <input
              type="text"
              name="youtube_link2"
              placeholder="यूट्यूब लिंक 2"
              value={youtubeLink2}
              onChange={(e) => setYoutubeLink2(e.target.value)}
            />
            <input
              type="file"
              name="image1"
              onChange={(e) => handleImageChange(e, setImage1)}
            />
            <input
              type="file"
              name="image2"
              onChange={(e) => handleImageChange(e, setImage2)}
            />
            <div>
              <span style={{ fontSize: "12px", color: "#888" }}>
                फोटो आकार: 50 केबी ते 1024 केबी, स्वरूपे: JPG किंवा PNG
              </span>
            </div>
            {imageWarning && (
              <div className="warning-message">{imageWarning}</div>
            )}
            <button type="submit">सेव करा</button>
          </form>
        </div>
      ) : (
        <div
          className="village-info-container"
          style={{ paddingRight: "40px" }}
        >
          <VillageForm initialData={editData} server={LOCALSERVER_API} />
        </div>
      )}

      <ToastContainer />
      <Modal
        title="आणखी फोटो सेव करा . "
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row style={{ display: "flex", justifyContent: "end" }}>
          <Button
            name="Add Field"
            onClick={() => {
              setDataField((prevDatafield) => [
                ...prevDatafield,
                { [`file-${prevDatafield.length + 1}`]: "" },
              ]);
            }}
            style={{ backgroundColor: "black", color: "white" }}
          >
            आणखी फोटो सेव करण्यासाठी येथे क्लिक करा.
          </Button>
        </Row>
<br />
        <form ref={formRef} id="image-upload" onSubmit={handleOk}>
          {datafield.map((field, index) => (
            <Row key={index}>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ padding: "0.3em", marginBottom: "0.3em" }}
              >
                <input
                  type="file"
                  placeholder={`Field ${index + 1}`}
                  name="files"
                  style={{ padding: "0.3em", width: "100%" }}
                  required
                />
                {modalData[`filed-${index + 1}`] && (
                  <div>Current Value: {modalData[`filed-${index + 1}`]}</div>
                )}
              </Col>
            </Row>
          ))}
          <Row style={{ display: "flex", justifyContent: "end"  }}>
            <button type="submit">सेव करा</button>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default VillageInfo;
