import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const VillageForm = ({ onSubmit, initialData, server }) => {
  const [formState, setFormState] = useState({
    village_name: "",
    description: "",
    youtubeLinks: [],
    images: [], // Both existing and newly uploaded images
  });
  const navigate = useNavigate();

  // UseEffect to transform initial data
  useEffect(() => {
    if (initialData) {
      console.log(initialData);
      const transformedData = {
        village_name: initialData.village_name || "",
        description: initialData.description || "",
        youtubeLinks: [
          initialData.youtube_link1 || "",
          initialData.youtube_link2 || "",
        ].filter((link) => link),
        images: [
          initialData.image1 || "",
          initialData.image2 || "",
          ...(typeof initialData.images === "string" &&
          initialData.images.trim()
            ? (() => {
                try {
                  const parsed = JSON.parse(initialData.images);
                  return typeof parsed === "string"
                    ? parsed.split(",")
                    : parsed;
                } catch (error) {
                  console.error("Error parsing images field:", error);
                  return [];
                }
              })()
            : []),
        ].filter((image) => image && image.trim()),
      };
      setFormState(transformedData);
    }
  }, [initialData]);
  console.log(formState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddYouTubeLink = () => {
    setFormState((prev) => ({
      ...prev,
      youtubeLinks: [...prev.youtubeLinks, ""],
    }));
  };

  const handleYouTubeLinkChange = (index, value) => {
    const updatedLinks = [...formState.youtubeLinks];
    updatedLinks[index] = value;
    setFormState((prev) => ({
      ...prev,
      youtubeLinks: updatedLinks,
    }));
  };

  const handleRemoveYouTubeLink = (index) => {
    const updatedLinks = formState.youtubeLinks.filter((_, i) => i !== index);
    setFormState((prev) => ({
      ...prev,
      youtubeLinks: updatedLinks,
    }));
  };

  const handleAddImage = (e) => {
    const files = Array.from(e.target.files);
    const previewImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFormState((prev) => ({
      ...prev,
      images: [...prev.images, ...previewImages],
    }));
  };

  const handleRemoveImage = (index) => {
    const updatedImages = formState.images.filter((_, i) => i !== index);
    setFormState((prev) => ({
      ...prev,
      images: updatedImages,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("village_name", initialData?.village_name);
    formData.append("description", formState.description);
    formData.append("village_id", initialData?.id);

    // Append YouTube links
    formState.youtubeLinks.forEach((link, index) =>
      formData.append(`youtube_link_${index + 1}`, link)
    );

    // Append images
    formState.images.forEach((image, index) => {
      if (image.file) {
        formData.append("files", image.file);
      } else {
        formData.append(`image_${index + 1}`, image);
      }
    });

    if (!initialData.village_name) {
      toast.error("Village Name is required");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("You are not logged in. Please log in.");
      navigate("/login");
      return;
    }

    formData["admin_id"] = localStorage.getItem("adminId");
    formData["candidate_id"] = localStorage.getItem("candidateId");

    try {
      const response = await axios.post(
        `${server}/editall/${initialData?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            candidate_id: localStorage.getItem("candidateId"),
            village_name: initialData?.village_name,
          },
        }
      );

      if (response.data.status_code === 401) {
        toast.error("Unauthorized: Please log in again.");
        navigate("/login");
      } else if (response.data.status_code === 200) {
        toast.success("Village added successfully");
        window.location.reload();
      } else {
        toast.error("Error in adding village");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized: Please log in again.");
        navigate("/login");
      } else {
        toast.error("Error in adding village");
      }
    }
  };
  const handleCancel = () => {
    window.location.reload(); // Reloads the current page
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4>ठिकाणाचे नाव : </h4>
      <input
        type="text"
        name="village_name"
        value={formState.village_name}
        onChange={handleInputChange}
        placeholder="Village Name"
        disabled
      />
      <h4>माहिती : </h4>
      <textarea
        name="description"
        value={formState.description}
        onChange={handleInputChange}
        placeholder="Description"
      />

      {/* YouTube Links */}
      <div>
        <h4>यूट्यूब लिंक्स :</h4>
        {formState.youtubeLinks.map((link, index) => (
          <div key={index} className="mb-2">
            <input
              type="text"
              value={link}
              onChange={(e) => handleYouTubeLinkChange(index, e.target.value)}
              placeholder={`YouTube Link ${index + 1}`}
            />
            {/* <button
              type="button"
              onClick={() => handleRemoveYouTubeLink(index)}
              style={{ background: "#ff0000ba" ,width: 'auto' }}
            >
              Remove
            </button> */}
          </div>
        ))}
        {/* <button type="button" onClick={handleAddYouTubeLink}>
          Add YouTube Link
        </button> */}
      </div>

      {/* Image Upload */}
      <div>
        <h4>फोटो :</h4>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleAddImage}
        />
        <div className="image-previews">
          {formState.images.map((image, index) => (
            <div key={index} className="preview">
              <img
                src={
                  image.preview ||
                  `https://village.pcube.in.net/uploads/${initialData?.candidate_id}/${image}`
                }
                alt={`Preview ${index + 1}`}
                width={100}
                height={100}
              />
              <button
                type="button"
                style={{ background: "#ff0000ba" }}
                onClick={() => handleRemoveImage(index)}
              >
                काढून टाका
              </button>
            </div>
          ))}
        </div>
      </div>

      <button type="submit">माहिती अपडेट करा</button>
      <button
        type="button"
        style={{  marginTop: "10px" }}
        onClick={handleCancel}
      >
        रद्द करा
      </button>
    </form>
  );
};

export default VillageForm;
