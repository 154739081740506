import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Register from "./components/Admin/Register";
import Login from "./components/Admin/Login";
import VillageInfo from "./components/Admin/VillageInfo";
import AllData from "./components/Admin/AllData";
import MyData from "./components/Admin/MyData";
import Dashboard from "./components/Admin/dashboard";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/village-info" element={<VillageInfo />} />
        <Route path="/all-data" element={<AllData />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/my-data" element={<MyData />} />

        <Route path="*" element={<div>Page Not Found</div>} />
      </Routes>
    </Router>
  );
};

export default App;
